<template>
  <div class="PageBox">
    <div class="ScrollBox">
    <div class="header_bg">
      <div style="text-align: right;font-size: 16px;padding: 5px">
        <van-row>
          <van-col span="18" @click="gotoApp" style="text-align: left">
            {{$t('common5[6]')}}
          </van-col>
          <van-col span="6" @click="showLang = true">{{$t('login.text[0]')}}</van-col>
        </van-row>
      </div>
      <div class="logo">
        <img :src="`./static/images/logo11.png`" width="220px" style="text-align: center"></img>
      </div>
    </div>
      <div class="loginpanel">
        <div style="padding-top: 10px;margin: 0 auto;text-align: center;width: 90%">
          <van-field
            :formatter="formatterUsername"
            :border="false"
            class="input"
            v-model.trim="postData.username"
            :placeholder="$t('login.placeholder[0]')"
            size="large"
            clearable
            autocomplete="off"
          />
        </div>
        <div class="msgPanel">
          <div v-if="showMsg">
          <img :src="`./static/icon/tip.png`" style="width: 20px"></img>
          </div>
          <div v-if="showMsg" style="margin-left: 5px">
            {{msg}}
          </div>
        </div>
        <div class="buttonPanel">
          <van-button block color="#DAA78A" @click="onNext()">
            {{ $t('login1[0]') }}
          </van-button>
        </div>
        <div class="buttonPanel">
            <div style="text-align: center;line-height: 50px">{{$t('login2[0]')}}</div>
          <van-button icon="./static/icon/facebook.png" block @click="clickBtns">
            {{ $t('login2[1]') }}{{ $t('login2[2]') }}
          </van-button>
          <van-button icon="./static/icon/google.png" block @click="clickBtns">
            {{ $t('login2[1]') }}{{ $t('login2[3]') }}
          </van-button>
          <van-button icon="./static/icon/apple.png" block @click="clickBtns">
            {{ $t('login2[1]') }}{{ $t('login2[4]') }}
          </van-button>
          <div style="line-height: 40px">
            {{$t('login.default[0]') }} |
            <router-link class="href" slot="a" to="/register">{{
              $t('login.default[1]')
            }}</router-link>
          </div>
          <div style="line-height: 40px">
            <router-link class="href" to="/resetpwd">{{
              $t('login.default[2]')
              }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <van-action-sheet
            v-model="showLang"
            :title="$t('language')"
            close-on-popstate
    >
        <!-- :icon="`./static/icon/en-US.png`" -->
      <div class="langPanel">
        <van-cell :style="chooseLang=='en-US'?'color:red':''"
                class="english"
                title="English"
                @click="changeLanguage('en-US')"
        >
        </van-cell>
        <!-- :icon="`./static/icon/vi-VN.png`" -->
        <van-cell :style="chooseLang=='vn'?'color:red':''"
                class="tieng"
                title="Tiếng Việt"
                @click="changeLanguage('vn')"
        >
        </van-cell>
        <van-cell :style="chooseLang=='th'?'color:red':''"
                class="tieng"
                title="ภาษาไทย"
                @click="changeLanguage('th')"
        >
        </van-cell>
        <!-- :icon="`./static/icon/zh-CN.png`" -->
        <!--          </van-cell>-->
        <van-cell :style="chooseLang=='zh-HK'?'color:red':''"
                class="china"
                title="繁體中文"
                @click="changeLanguage('zh-HK')"
        >
        </van-cell>
      </div>
      <div style="padding: 16px;padding-bottom: 5px">
        <p class="btn" @click="setlang">{{ $t("userInfo.default[12]") }}</p>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
  import Service from "@/common/Service";

export default {
  name: "Login",
  components: {},
  props: ["username"],
  data() {
    return {
      chooseLang:'',
      showLang:false,
      postData: {
        username: localStorage["UserName"] || "",
        password: localStorage["PassWord"] || "",
        uniqueID: localStorage["phoneId"] || "", //手机唯一标识
      },
      showMsg:false,
      msg:"ssss",
      keepUser: localStorage["KeepUser"] == "1" ? true : false,
      isSubmit: false,
      showPass: false,
      language1: localStorage.getItem("Language") == "cn",
    };
  },
  computed: {},
  watch: {},
  created() {
    this.isSubmit = false;
    // var storage = require('@google-cloud/storage');
    // var fs = require('fs');
    //
    // var gcs = storage({
    //   projectId: 'grape-spaceship-123',
    //   keyFilename: '/path/to/keyfile.json'
    // });

    console.log(this.language1);
  },
  mounted() {
    var query= this.$route.query;
    if (query["username"]){
      this.postData.username = query["username"];
    }
  },
  activated() {},
  destroyed() {},
  methods: {
    changeLanguage(lan) {
      this.chooseLang = lan;
    },
    setlang(){
      this.$SetLanguage(this.chooseLang);
      if (this.showLang) {
        this.showLang = false;
      }
    },
    onNext() {
      this.showMsg = false;
      if (!this.postData.username) {
        this.msg = this.$t("login.placeholder[0]");
        this.showMsg = true;
        // this.$Dialog.Toast();
        return;
      }
      this.$Model.Login(this.postData, (data) => {
        // this.$Model.WriteLog({loginret:data})
        if (data.code==0){
          let idx = data.code_dec;
          let msg = this.$t("login.codes['"+idx+"']");
          this.$toast(msg);
          return;
        }else if (data.code==2){
          this.msg = this.$t("login.placeholder[0]");
          this.showMsg = true;
        }if (data.code==3){
          var header = data.code_dec;
          this.$router.push("/loginVerify?username="+this.postData.username+"&header="+header)
        }

      });
    },
    changeKeepUser() {
      var appData = {};
      try {
        var data = localStorage["AppData"];
        if (data) {
          appData = JSON.parse(data);
        }
      } catch (e) {
        // alert('3'+e.message)
        appData = {};
      }
      if (this.keepUser) {
        appData["KeepUser"] = 1;
        localStorage["KeepUser"] = 1;
      } else {
        appData["KeepUser"] = 0;
        localStorage.removeItem("KeepUser");
      }
      var data = JSON.stringify(appData);
      localStorage["AppData"] = data;
      this.$Util.writeAppStorage(data);
    },
    // 用户名格式化不能输入空格
    formatterUsername(value) {
      return value.replace(/\s*/g, "");
    },
    gotoApp(){
      window.location.href = this.InitData.setting.app_url;
    },
    doShow2(){
      _MEIQIA('showPanel');
    },
  },
};
</script>
<style scoped>
.LoginHeader {
  padding: 0 0;
}

.PageBox {
  color: #e7e7e7;
  padding: 0;
  background-color: #0F4F4E;
}
.ScrollBox {

}

.loginpanel {
  height:500px;
  margin: 15px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #28605F;
  background-size: cover;
  /*background-color: #191C23;*/
  padding-bottom: 60px;
}

.msgPanel {
  display: flex;
  height:35px;
  margin:0 auto;
  width: 90%;
  color: #e7e7e7;
  padding-left: 10px;
  margin-top: -5px;
  margin-bottom: 15px;
}
.msgPanel div{
  line-height: 60px;
}
.buttonPanel {
  margin: 0 auto;width: 90%;
}

.buttonPanel .van-button{
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 20px;
}
.logo {
  margin-left: 90px;
  margin-top: 10px;
  padding-bottom: 30px;
}

.loginpanel img {
  margin: 20px auto;
  text-align: center;
  width: 200px;
}

.PageBox a:link {
  color: #635327;
}


.van-cell{
  border: 1px solid #DAA78A;
}
.van-cell--borderless.input {
  background-color: #337F7B;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 25px;
  padding: 0 30px 0 13px;
  height: 45px;
}
.van-cell >>> .van-field__body {
  height: 100%;
}
.van-cell >>> .van-icon-closed-eye {
  font-size: 19px;
  color: #FFFEFF;
  margin-top: 5px;
}
.van-cell--borderless >>> .van-cell__value--alone {
  height: 100%;
}

.van-cell--borderless >>> .van-checkbox__label {
  color: #555 !important;
  font-size: 13px;
  font-family: Alibaba;
  margin-top: -1px;
}

.van-cell--borderless >>> .van-icon-success {
  border: 1px solid #ff93a4;
  box-shadow: 0px 0px 0px 0.5px #ff93a4;
  margin-left: 1px;
}


.van-cell--borderless >>> .van-icon {
  font-size: 25px !important;
  color: #89BFBF !important;
  margin-top: 6px;
}

.van-cell--borderless.input >>> .van-field__control {
  color: #FFFEFF !important;
  padding-left: 7px;
  height: 100%;
  font-size: 18px;
}

.van-cell--borderless.input >>> .van-field__control::-webkit-input-placeholder {
  color: #FFFEFF;
  font-size: 19px;
  font-family: Alibaba;
}

.van-cell--borderless.input >>> .van-field__control::-moz-placeholder {
  color: #FFFEFF;
  font-size: 19px;
  font-family: Alibaba;
}

.van-cell--borderless.input >>> .van-field__control::-ms-input-placeholder {
  color: #FFFEFF;
  font-size: 18px;
  font-family: Alibaba;
}

.langPanel >>> .van-cell {
  border: 0px;
  text-align: center;
}

.van-form {
  padding: 0 35px;
}

.goRe a {
  color: #fe889d;
}

.van-cell--borderless >>> .van-icon-manager,
.van-cell--borderless >>> .van-icon-lock {
  color: #ccc !important;
}

.van-cell--borderless >>> .van-icon-manager::before,
.van-cell--borderless >>> .van-icon-lock::before {
  font-size: 27px;
  margin-top: 4px;
}

.van-cell--large {
  padding-top: 17px;
  padding-bottom: 8px;
}

.Site .van-nav-bar >>> .van-icon {
  color: #333;
}

.van-nav-bar__text1 >>> .van-nav-bar__text {
  letter-spacing: 0.09rem;
  color: #000000;
}

.van-button__content .van-button__text {
  font-size: 16px;
  white-space: nowrap;
}
.van-button__icon {
  margin-left: -100px;
  width: 120px;
}
.PageBox >>> .letter_s {
  letter-spacing: 0.08rem;
}
.goRe {
  margin-top: 20px;
  font-family: Alibaba;
  font-size: 15px;
  text-align: left;
  color: #4e51bf;
}

.href {
  color: #FFFEFF;
}

.PageBox a:link {
  color: #FFFEFF;
}

.btn {
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  background-color: #DAA78A;
  color: #FFFEFF;
  font-size: 18px;
  text-align: center;
  margin: 0px auto;
  margin-bottom: 10px;
}
</style>
